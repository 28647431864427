<template lang="html">
  <Data class="page-api" :showMenu="true">
    <v-card class="page-api__wrapper 2 pa-md-5 mx-auto" max-width="750px" elevation="0" rounded="lg">
      <v-row v-if="publicApiEnabled" class="px-5" align="center" justify="center" no-gutters>
        <v-col cols="12">
          <h2 class="headline font-weight-bold mb-0">
            {{ $t('api.docs') }}
          </h2>
        </v-col>

        <v-col cols="12" class="mt-3 mb-5">
          <ul>
            <li>
              <a
                href="https://app.swaggerhub.com/apis-docs/Signi/Signi/1.3.1#/CreateContractJsonFileStructure"
                class="font-weight-medium"
                target="_blank"
              >
                {{ $t('api.docs') }}
              </a>
            </li>
            <li>
              <a :href="$t('links.integration')" class="font-weight-medium" target="_blank">
                {{ $t('api.integrations') }}
              </a>
            </li>
          </ul>
        </v-col>

        <v-col cols="12">
          <h2 class="headline font-weight-bold mb-0">
            {{ $t('api.keys') }}
          </h2>
        </v-col>

        <v-col cols="12" class="mt-1 mb-5">
          <v-row v-if="canEditWorkspaceApiSettings" align="center" justify="center">
            <v-col cols="12" class="mt-5 text-center">
              <StorePasswordDialog />
            </v-col>
          </v-row>

          <v-row v-else align="center" justify="center" class="pt-0">
            <v-col v-if="remainingTime !== 0" cols="12" class="px-3">
              <v-progress-linear :buffer-value="remainingTime"></v-progress-linear>
            </v-col>

            <v-col v-if="canFindWorkspaceByTitle" cols="12">
              <v-text-field
                v-model="searchTerm"
                :label="$t('api.find_workspace')"
                prepend-inner-icon="mdi-magnify"
                hide-details
                outlined
              ></v-text-field>
            </v-col>

            <v-col v-for="workspace in filteredWorkspaces" :key="workspace.id" cols="12">
              <WorkspaceApiSettings :workspace="workspace" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-else class="pa-5" align="center" justify="center" no-gutters>
        <v-col class="col-12 info-text">
          <p class="subtitle-2" v-html="$t('api.not_connected.info')"></p>
          <p class="subtitle-2" v-html="$t('api.not_connected.info_2')"></p>
          <p class="subtitle-2" v-html="$t('api.not_connected.info_3')"></p>
          <p class="subtitle-2" v-html="$t('api.not_connected.info_4')"></p>
          <p class="subtitle-2" v-html="$t('api.not_connected.info_5')"></p>
        </v-col>
      </v-row>
    </v-card>
  </Data>
</template>

<script>
import { mapGetters } from 'vuex'
import StorePasswordDialog from '@apiDoc/components/StorePasswordDialog'
import WorkspaceApiSettings from '@apiDoc/components/WorkspaceApiSettings'

export default {
  name: 'ApiDoc',
  components: {
    StorePasswordDialog,
    WorkspaceApiSettings,
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      remainingTime: 'apiDoc/remainingTime',
      userPassword: 'apiDoc/userPassword',
    }),
    canEditWorkspaceApiSettings() {
      return null === this.userPassword
    },
    canFindWorkspaceByTitle() {
      return this.profile?.workspaces?.length > 2
    },
    filteredWorkspaces() {
      if (!this.canFindWorkspaceByTitle) {
        return this.profile.workspaces
      }

      return this.profile.workspaces.filter((workspace) =>
        workspace.title.toLowerCase().includes(this.searchTerm.toLowerCase()),
      )
    },
    publicApiEnabled() {
      return this.profile?.workspaces?.some((workspace) => workspace?.feature_flags?.includes('publicApiEnabled'))
    },
  },
}
</script>

<style lang="scss" scoped>
.page-api {
  &__wrapper {
    border: 1px solid #d4dfef;
  }

  h2 {
    color: #8a3fb9;
    border-bottom: 1px solid #dbe6f7;
  }

  ul {
    list-style-position: inside;
  }
}

:deep(.info-text a) {
  font-size: inherit !important;
  font-weight: inherit !important;
}
</style>
