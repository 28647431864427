<template>
  <v-dialog v-model="show" max-width="750px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="isGoogleAuthAccount || isMicrosoftAuthAccount" color="primary" max-width="15rem" width="100%" @click="onVerifyAccess">
        {{ $t('general.verify_access') }}
      </v-btn>

      <v-btn v-else color="primary" max-width="15rem" width="100%" v-bind="attrs" v-on="on">
        {{ $t('password.type') }}
      </v-btn>
    </template>

    <v-card class="py-2 px-1" elevation="0">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="px-5 pb-10 text-center">
        <v-text-field
          v-model="password"
          autofocus
          type="password"
          name="password"
          class="password-input mx-auto pb-3"
          :label="$t('password.type')"
          :error="isPasswordWrong"
          :messages="errorMessage !== '' ? errorMessage : ''"
          :hide-details="errorMessage === ''"
          @input="onPasswordChange"
          @keydown.enter="onStorePassword"
        ></v-text-field>

        <v-btn
          color="primary"
          max-width="20rem"
          width="100%"
          :disabled="isStorePasswordDisabled"
          :loading="isStoringPassword"
          @click="onStorePassword"
        >
          {{ $t('general.continue') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useGoogleSignInPopup } from '@/features/access/services/authenticationService'
import { useMicrosoftSignInPopup } from '@/features/access/services/authenticationService'

export default {
  name: 'StorePasswordDialog',
  data() {
    return {
      show: false,
      password: '',
      passwordError: false,
      errorMessage: '',
      submitted: false,
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      userPassword: 'apiDoc/userPassword',
    }),
    isPasswordWrong() {
      return this.passwordError
    },
    isStorePasswordDisabled() {
      return this.password.length <= 2 || this.passwordError
    },
    isStoringPassword() {
      return this.submitted
    },
    isGoogleAuthAccount() {
      return this.profile?.providers?.isGoogleType && !this.profile?.isPasswordSet
    },
    isMicrosoftAuthAccount() {
      return this.profile?.providers?.isAzureType && !this.profile?.isPasswordSet
    }
  },
  methods: {
    ...mapActions({
      storeUserPassword: 'apiDoc/storeUserPassword',
    }),
    onPasswordChange() {
      this.passwordError = false
    },
    onVerifyAccess() {
      try {
        if(this.isGoogleAuthAccount) {
          useGoogleSignInPopup().then((resp) => {
            this.storeUserPassword({ password: null, googleTokenId: resp?.idToken, azureTokenId: null }).catch(() => {
              this.$notification.error(this.$t('access_verification.failed'))
            })
          })
        } else {
          useMicrosoftSignInPopup().then((resp) => {
            this.storeUserPassword({ password: null, googleTokenId: null, azureTokenId: resp?.accessToken }).catch(() => {
              this.$notification.error(this.$t('access_verification.failed'))
            })
          })
        }
      } catch (e) {
        this.$notification.error(this.$t('access_verification.failed'))
      }
    },
    onStorePassword() {
      this.submitted = true

      this.storeUserPassword({ password: this.password, tokenId: null }).catch(() => {
        this.errorMessage = this.$t('api.errors.invalid_password')
        this.passwordError = true
        this.submitted = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.password-input {
  max-width: 25rem;
  width: 100%;
}
</style>
